import React from "react";
import Card from "./Card";

class CardRow extends React.Component {
  render() {
    const cards = this.props.talent.map((t, i, arr) => {
      return (
        <Card
          key={this.props.id * arr.length + i}
          id={this.props.id * arr.length + i}
          talent={t}
          width={100 / arr.length}
        ></Card>
      );
    });
    return <div className="talent-card-row">{cards}</div>;
  }
}

export default CardRow;
