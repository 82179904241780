import React from "react";
import SkillSelector from "./SkillSelector";

class TagManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_tags: [],
      hovered_tag: "",
      dividers: []
    };
  }

  tagClasses = selected => {
    let classes = "talent-tag";
    if (selected) classes = `${classes} selected`;

    return classes;
  };

  isSelected = tag => {
    return this.props.activeTags().indexOf(tag) !== -1;
  };

  render = () => {
    const tag_els = this.props.tags.map((tag, i, arr) => {
      return (
        <React.Fragment key={i}>
          <div className="tag" ref={`tag${i}`}>
            <SkillSelector
              text={tag.text}
              skill={tag.skill}
              color={tag.color ? tag.color : "black"}
              setTag={this.props.setTag}
            ></SkillSelector>
            <div className="talent-divider">-</div>
          </div>
        </React.Fragment>
      );
    });

    return (
      <div className="talent-tag-wrapper">
        {tag_els}
        <div className="tag">
          <SkillSelector
            text={"clear all"}
            skill={""}
            color={"#333333"}
            setTag={this.props.setTag}
          ></SkillSelector>
        </div>
      </div>
    );
  };
}

export default TagManager;
