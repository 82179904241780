import React from "react";
import ReactDOM from "react-dom";
import CardRow from "./CardRow";

class TalentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rect: ""
    };
  }

  componentDidMount = () => {
    this.props.set(ReactDOM.findDOMNode(this));
  };

  render() {
    const talent = this.props.talent;
    const row_size = this.props.rowSize;
    const talent_rows = new Array(Math.ceil(talent.length / row_size))
      .fill(0)
      //Get row_size length slices of 1D Talent Array
      .map((row, i) =>
        this.props.talent.slice(i * row_size, i * row_size + row_size)
      )
      //Convert them to React Component
      .map((row, i, arr) => {
        let bottom = "";
        if (i === arr.length - 1) {
          bottom = <hr />;
        }
        return (
          <React.Fragment key={i * this.props.id}>
            <hr />
            <CardRow key={i} id={i} talent={row}></CardRow>
            {bottom}
          </React.Fragment>
        );
      });

    return <div className="talent-card-view">{talent_rows}</div>;
  }
}

export default TalentView;
