const talent = [
  {
    type: "",
    name: "The Wade Brothers",

    bio: "",
    stats: [],
    skills: ["Director", "Photographer"],
    reel:
      "https://player.vimeo.com/external/334529586.sd.mp4?s=4f899caf450af3fb8c92a365fcf2e15a40dd5fc6&profile_id=165"
  },
  {
    type: "",
    name: "Nick Dunne",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_NickDunne_Overview_001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_NickDunne_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_NickDunne_Overview_015.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_NickDunne_Overview_024.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_NickDunne_Overview_030.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_NickDunne_Overview_044.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_NickDunne_Overview_053.jpg"
    ]
  },
  {
    type: "",
    name: "Mortis Studio",
    bio: "",
    stats: [],
    skills: ["Animator", "VFX/designer", "Mixed media artist"],
    skill_override: ["Creative Agency"],
    reel:
      "https://player.vimeo.com/external/416851309.hd.mp4?s=4af76d465a389b6285cc3f4a066ce5f2b326bf18&profile_id=175"
  },
  {
    type: "",
    name: "Jonny Alexander",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist"],
    skill_override: ["Illustrator", "Muralist"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JonnyAlexander_Overview_001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JonnyAlexander_Overview_004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JonnyAlexander_Overview_011.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JonnyAlexander_Overview_019.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JonnyAlexander_Overview_027.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JonnyAlexander_Overview_043.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JonnyAlexander_Overview_066.jpg"
    ]
  },
  {
    type: "",
    name: "Trevor Hawkins",

    bio: "",
    stats: [],
    skills: ["Director", "Cinematographer", "Photographer"],
    reel:
      "https://player.vimeo.com/progressive_redirect/playback/731167038/rendition/1080p/file.mp4?loc=external&signature=a896ac07c2f585cfd3f0d26cd3a79c996584d597d3ff33e698c915e8eede2eb8"
  },
  {
    type: "",
    name: "Yeni Kim",
    bio: "",
    stats: [],
    skills: ["Mixed media artist"],
    skill_override: ["Paper Artist"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeniKim_Overview_067.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeniKim_Overview_072.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeniKim_Overview_073.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeniKim_Overview_078.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeniKim_Overview_080.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeniKim_Overview_085.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeniKim_Overview_101.jpg"
    ]
  },
  {
    type: "",
    name: "YeYe Weller",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist"],
    skill_override: ["Illustrator"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeyeWeller_Overview_003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeyeWeller_Overview_006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeyeWeller_Overview_008.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeyeWeller_Overview_017.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeyeWeller_Overview_022.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeyeWeller_Overview_024.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_YeyeWeller_Overview_015.jpg"
    ]
  },
  {
    type: "",
    name: "Grace Chon",

    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      "https://player.vimeo.com/external/334531609.sd.mp4?s=019fb759797131fac5b5d20d2ba85172c2f50378&profile_id=165"
  },
  {
    type: "",
    name: "Blake Farber",
    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/632314197.hd.mp4?s=51cce269bc99718f480d6d55b221356e781a46c9&profile_id=174"
  },
  {
    type: "",
    name: "Bryan Schlam",

    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/335054904.sd.mp4?s=dd53027d83e9ded42ea01e12741abf5ecb528487&profile_id=165"
  },
  {
    type: "",
    name: "A Collective Us",

    bio: "",
    stats: [],
    skills: ["Director", "Photographer"],
    reel:
      "https://player.vimeo.com/external/334529257.sd.mp4?s=b88524d22795a6f562a087a830149ed76d155cbf&profile_id=165"
  },
  {
    type: "",
    name: "Kell Mitchell",

    bio: "",
    stats: [],
    skills: ["Director", "Photographer"],
    reel:
      "https://player.vimeo.com/external/334529389.hd.mp4?s=60d978ed0676a929c78b39e77de74a8fce0b8857&profile_id=174"
  },
  {
    type: "",
    name: "Bryan Rodner Carr",

    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      "https://player.vimeo.com/external/334531596.sd.mp4?s=92b80920030ea6bca70e94ea673fd1c32b325862&profile_id=165"
  },
  {
    type: "",
    name: "Jesse Whitaker",

    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/335054921.sd.mp4?s=45d2e78d0210b4f8b1cab4d8c635ec9d30d1c6c0&profile_id=165"
  },
  {
    type: "",
    name: "Megan Galante",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist"],
    skill_override: ["Illustrator"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MeganGalante_Overview_005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MeganGalante_Overview_008.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MeganGalante_Overview_011.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MeganGalante_Overview_016.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MeganGalante_Overview_022.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MeganGalante_Overview_029.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MeganGalante_Overview_043.jpg"
    ]
  },
  {
    type: "",
    name: "Summer Murdock",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      "https://player.vimeo.com/external/334529448.sd.mp4?s=3651364b0b3bb093fe80a3986e6a966a73627b5c&profile_id=165"
  },
  {
    type: "",
    name: "Ozzie Hoppe",

    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      "https://player.vimeo.com/external/335054935.sd.mp4?s=656174328999ba01994b2fe0736e9376886c55c9&profile_id=165"
  },
  {
    type: "",
    name: "Matt Mooney",

    bio: "",
    stats: [],
    skills: ["Photographer", "Director"],
    reel:
      "https://player.vimeo.com/external/334529400.sd.mp4?s=4adec88c52786bedd682b3f6a77b7689ea80b482&profile_id=165"
  },
  
  {
    type: "",
    name: "Julian Love",

    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      "https://player.vimeo.com/external/334531619.sd.mp4?s=fe78782dfcea942a121a7ee64c8091f2255fad21&profile_id=165"
  },
  {
    type: "",
    name: "Justin Carrasquillo",

    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      "https://player.vimeo.com/external/334529363.sd.mp4?s=30160a4f7e91f50f80b37eebb5d3ae5ee94916ca&profile_id=165"
  },
  {
    type: "",
    name: "Moh Azima",

    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/334529427.sd.mp4?s=a140a21443b6acdd66ee99b9cb79784a75ed664b&profile_id=165"
  },
  {
    type: "",
    name: "Shawn Brackbill",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-ShawnBrackbill-Overview-00051.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-ShawnBrackbill-Overview-00072.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-ShawnBrackbill-Overview-00082.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-ShawnBrackbill-Overview-00049.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-ShawnBrackbill-Overview-00012.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-ShawnBrackbill-Overview-00030.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-ShawnBrackbill-Overview-00032.jpg"
    ]
  },
  {
    type: "",
    name: "Richard Allen",

    bio: "",
    stats: [],
    skills: ["Photographer", "Director"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-RichardAllen-Overview-00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-RichardAllen-Overview-00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-RichardAllen-Overview-00008.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-RichardAllen-Overview-00017.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-RichardAllen-Overview-00034.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-RichardAllen-Overview-00049.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2-RichardAllen-Overview-00043.jpg"
    ]
  },
  {
    type: "",
    name: "Lisa Tegtmeier",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist"],
    skill_override: ["Illustrator"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LisaTegtmeier_Overview_014.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LisaTegtmeier_Overview_016.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LisaTegtmeier_Overview_004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LisaTegtmeier_Overview_028.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LisaTegtmeier_Overview_002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LisaTegtmeier_Overview_009.jpg"
    ]
  },
  {
    type: "",
    name: "Justinas Vilutis",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JustinasVilutis_Overview_001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JustinasVilutis_Overview_023.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JustinasVilutis_Overview_033a.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JustinasVilutis_Overview_036.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JustinasVilutis_Overview_004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JustinasVilutis_Overview_040.jpg"
    ]
  },
  {
    type: "",
    name: "Whitney Anderson",
    bio: "",
    stats: [],
    skills: ["VFX/designer", "Illustrator/muralist"],
    skill_override: ["Designer", "Illustrator"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_WhitneyAnderson_Overview_002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_WhitneyAnderson_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_WhitneyAnderson_Overview_011.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_WhitneyAnderson_Overview_022.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_WhitneyAnderson_Overview_063.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_WhitneyAnderson_Overview_078.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_WhitneyAnderson_Overview_050.jpg"
    ]
  },
  {
    type: "",
    name: "Kelby Reck",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_KelbyReck_Overview_006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_KelbyReck_Overview_012.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_KelbyReck_Overview_013.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_KelbyReck_Overview_014b.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_KelbyReck_Overview_025.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_KelbyReck_Overview_030b.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_KelbyReck_Overview_038.jpg"
    ]
  },
  {
    type: "",
    name: "Molly Steele",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MollySteele_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MollySteele_Overview_00016.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MollySteele_Overview_00021.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MollySteele_Overview_00022.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MollySteele_Overview_00028.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MollySteele_Overview_00039.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MollySteele_Overview_00055.jpg"
    ]
  },
  {
    type: "",
    name: "Genesis Motion Design",
    bio: "",
    stats: [],
    skills: ["Animator"],
    skill_override: ["Motion Designers"],
    reel:
      "https://player.vimeo.com/external/408843000.hd.mp4?s=abce2fc8d9567bf9959953ebfd1da14f8f8c3888&profile_id=175"
  },
  {
    type: "",
    name: "Sinjun Strom",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SinjunStrom_Overview_010.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SinjunStrom_Overview_012.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SinjunStrom_Overview_008.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SinjunStrom_Overview_019.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SinjunStrom_Overview_029.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SinjunStrom_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SinjunStrom_Overview_013.jpg"
    ]
  },
  {
    type: "",
    name: "Lucia Walter",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist"],
    skill_override: ["Illustrator"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LuciaWalter_Overview_003d.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LuciaWalter_Overview_023a.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LuciaWalter_Overview_026b.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LuciaWalter_Overview_026c.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LuciaWalter_Overview_029b.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LuciaWalter_Overview_030b.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LuciaWalter_Overview_003c.jpg"
    ]
  },
  {
    type: "",
    name: "Sarah Sullivan",
    bio: "",
    stats: [],
    skills: ["Mixed media artist"],
    skill_override: ["Muralist", "Textile Artist"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SarahSullivan_Overview_010.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SarahSullivan_Overview_004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SarahSullivan_Overview_009.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SarahSullivan_Overview_018.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SarahSullivan_Overview_019.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SarahSullivan_Overview_015.jpg"
    ]
  },
  {
    type: "",
    name: "She She",
    bio: "",
    stats: [],
    skills: ["Mixed media artist"],
    skill_override: ["Installation Artist"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SheShe_Overview_001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SheShe_Overview_006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SheShe_Overview_009.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SheShe_Overview_012.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SheShe_Overview_014.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SheShe_Overview_016.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SheShe_Overview_027.jpg"
    ]
  },
  {
    type: "",
    name: "Greta Rybus",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GretaRybus_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GretaRybus_Overview_026.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GretaRybus_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GretaRybus_Overview_044.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GretaRybus_Overview_045.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GretaRybus_Overview_046.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GretaRybus_Overview_048.jpg"
    ]
  },
  {
    type: "",
    name: "Xuebing Du",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_XuebingDu_Overview_00010.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_XuebingDu_Overview_00022.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_XuebingDu_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_XuebingDu_Overview_00030.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_XuebingDu_Overview_00008.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_XuebingDu_Overview_00031.jpg"
    ]
  },
  {
    type: "",
    name: "Guillaume Lechat",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GuillaumeLechat_Overview_005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GuillaumeLechat_Overview_018.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GuillaumeLechat_Overview_028.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GuillaumeLechat_Overview_049.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GuillaumeLechat_Overview_060.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GuillaumeLechat_Overview_067.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_GuillaumeLechat_Overview_093.jpg"
    ]
  },
  {
    type: "",
    name: "Julien Roubinet",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JulienRoubinet_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JulienRoubinet_Overview_021.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JulienRoubinet_Overview_039.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JulienRoubinet_Overview_043.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JulienRoubinet_Overview_035a.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_JulienRoubinet_Overview_009.jpg"
    ]
  },
  {
    type: "",
    name: "Sun Lee",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SunLee_Overview_005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SunLee_Overview_005a.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SunLee_Overview_012.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SunLee_Overview_017.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SunLee_Overview_019.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SunLee_Overview_021.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_SunLee_Overview_063.jpg"
    ]
  },
  {
    type: "",
    name: "Brock DeBoer",
    bio: "",
    stats: [],
    skills: ["Mixed media artist"],
    skill_override: ["Sculptor", "Installation Artist"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_BrockDeboer_Overview_001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_BrockDeboer_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_BrockDeboer_Overview_009.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_BrockDeboer_Overview_019.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_BrockDeboer_Overview_030.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_BrockDeboer_Overview_058.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_BrockDeboer_Overview_027.jpg"
    ]
  },
  {
    type: "",
    name: "Maria Piessis",
    bio: "",
    stats: [],
    skills: ["Mixed media artist", "photographer"],
    skill_override: ["Mixed Media Artist", "photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MariaPiessis_Overview_007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MariaPiessis_Overview_011.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MariaPiessis_Overview_012.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MariaPiessis_Overview_015.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MariaPiessis_Overview_019.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MariaPiessis_Overview_024.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MariaPiessis_Overview_034.jpg"
    ]
  },
  {
    type: "",
    name: "Ashley Mary",
    bio: "",
    stats: [],
    skills: ["VFX/designer"],
    skill_override: ["Designer", "Muralist"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AshleyMary_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AshleyMary_Overview_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AshleyMary_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AshleyMary_Overview_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AshleyMary_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AshleyMary_Overview_00006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AshleyMary_Overview_00007.jpg"
    ]
  },
  {
    type: "",
    name: "Kaley From Kansas",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KaleyFromKansas_Portraits_00008.jpg"
    ]
  },
  {
    type: "",
    name: "Maddy Nye",
    bio: "",
    stats: [],
    skills: ["VFX/designer"],
    skill_override: ["Designer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MaddyNye_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MaddyNye_Overview_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MaddyNye_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MaddyNye_Overview_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MaddyNye_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MaddyNye_Overview_00006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MaddyNye_Overview_00007.jpg"
    ]
  },
  {
    type: "",
    name: "Mathew Scott",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MatthewScott_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MatthewScott_Overview_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MatthewScott_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MatthewScott_Overview_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MatthewScott_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_MatthewScott_Overview_00006.jpg"
    ]
  },
  {
    type: "",
    name: "Moos-Tang",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_MoosTang_Overview_00008.jpg"
    ]
  },
  {
    type: "",
    name: "Sarah Pannell",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_SarahPannell_Overview_00008.jpg"
    ]
  },
  {
    type: "",
    name: "Quixotic",
    bio: "",
    stats: [],
    skills: ["Experiential"],
    reel:
      "https://player.vimeo.com/external/509648234.sd.mp4?s=0d85751f8caba282c05616699f7037811181b1ad&profile_id=165"
  },
  {
    type: "",
    name: "Joe Perri",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      [
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00003.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00014.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00008.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00001.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00010.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00004.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00015.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_JoePerri_Overview_00007.jpg"
      ]
  },
  {
    type: "",
    name: "Ryan Lovell",
    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/536024045.sd.mp4?s=464176ffa2ef1e08ec77314575d4b63166f3fd4f&profile_id=165"
  },
  {
    type: "",
    name: "Logan McNay",
    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/531218060.hd.mp4?s=fae109c5dfc9b55cbcd526f02f4dd289c200cf46&profile_id=175"
  },
  {
    type: "",
    name: "Ben Kocinski",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist", "VFX/designer"],
    skill_override: ["Illustrator", "Muralist & Designer"],
    reel:
      [
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_BenKocinski_Overview_00002.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_BenKocinski_Overview_00001.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_BenKocinski_Overview_00005.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_BenKocinski_Overview_00006.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_BenKocinski_Overview_00003.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_BenKocinski_Overview_00004.jpg"
      ]
  },
  {
    type: "",
    name: "Phillip Reese",
    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/534709758.hd.mp4?s=d9d66f0e5c031e3564b6d2887a4963fec8241b1c&profile_id=174"
  },
  {
    type: "",
    name: "Andy Koeger",
    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/553554031.hd.mp4?s=a813409a0f8a797e7f83074dddfc2214d9bf6d96&profile_id=175"
  },
  {
    type: "",
    name: "Djelissa Latini",
    bio: "",
    stats: [],
    skills: ["VFX/designer"],
    skill_override: ["Designer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_DjelissaLatini_Overview_000a.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_DjelissaLatini_Overview_004a.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_DjelissaLatini_Overview_00009.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_DjelissaLatini_Overview_00014.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_DjelissaLatini_Overview_00021.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_DjelissaLatini_Overview_00024.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_DjelissaLatini_Overview_00023.jpg"
    ]
  },
  {
    type: "",
    name: "Lissyelle Laricchia",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LissyelleLaricchia_Overview_031.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LissyelleLaricchia_Overview_033.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LissyelleLaricchia_Overview_035.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LissyelleLaricchia_Overview_046.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LissyelleLaricchia_Overview_049.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LissyelleLaricchia_Overview_051.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/RW2_LissyelleLaricchia_Overview_054.jpg"
    ]
  },
  {
    type: "",
    name: "Andrew Lichtenstein",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel:
      [
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00014.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00029.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00016.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00004.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00033.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00024.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00030.jpg",
        "https://arthousedev.wpengine.com/wp-content/uploads/AH_AmericanPhotographer_Overview_00019.jpg"
      ]
  },
  {
    type: "",
    name: "Voca Films",
    bio: "",
    stats: [],
    skills: ["Director"],
    reel:
      "https://player.vimeo.com/external/553575763.hd.mp4?s=fcd21b13daa5c7a2614d530d5bfecf1c7cc27c02&profile_id=175"
  },
  {
    type: "",
    name: "Hyesu Lee",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist"],
    skill_override: ["Illustrator"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_HyesuLee_Overview_00011.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_HyesuLee_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_HyesuLee_Overview_00006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_HyesuLee_Overview_00017.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_HyesuLee_Overview_00016.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_HyesuLee_Overview_00028.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_HyesuLee_Overview_00008.jpg"
    ]
  },
  {
    type: "",
    name: "Dylan Johnston",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_DylanJohnson_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_DylanJohnson_Overview_00011.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_DylanJohnson_Overview_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_DylanJohnson_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_DylanJohnson_Overview_00012.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_DylanJohnson_Overview_00009.jpg"
    ]
  },
  {
    type: "",
    name: "Joel Cares",

    bio: "",
    stats: [],
    skills: ["Director", "Animator"],
    reel:
      "https://player.vimeo.com/external/567181384.hd.mp4?s=724fc85e867c5ca799a7d427871654f4f9ee6a8b&profile_id=175"
  },
  {
    type: "",
    name: "Nick Fancher",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00016.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00017.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00015.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00014.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_NickFancher_Overview_00007.jpg"
    ]
  },
  {
    type: "",
    name: "Daniel Goggin",
    bio: "",
    stats: [],
    skills: ["Director", "Experiential"],
    reel:
      "https://player.vimeo.com/external/571813656.hd.mp4?s=96a113c01ab1486872a522f4e25f37fd51ad1ab5&profile_id=174"
  },
  {
    type: "",
    name: "Frank Norton",
    bio: "",
    stats: [],
    skills: ["Illustrator/muralist", "VFX/designer"],
    skill_override: ["Illustrator", "Designer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00007.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_FrankNorton_Overview_00008.jpg"
    ]
  },
  {
    type: "",
    name: "Omar Khaleel",
    bio: "",
    stats: [],
    skills: ["Photographer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_OmarKhaleel_Commercial_00020.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_OmarKhaleel_Commercial_00008.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_OmarKhaleel_Commercial_00015.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_OmarKhaleel_Commercial_00002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_OmarKhaleel_Commercial_00005.jpg"
    ]
  },
  {
    type: "",
    name: "Zachary Schinella",
    bio: "",
    stats: [],
    skills: ["Designer"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_ZacharySchinella_Overview_001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_ZacharySchinella_Overview_002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_ZacharySchinella_Overview_003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_ZacharySchinella_Overview_004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_ZacharySchinella_Overview_005.jpg"
    ]
  },
  {
    type: "",
    name: "Afton Lin",
    bio: "",
    stats: [],
    skills: ["Designer", "Illustrator/muralist"],
    skill_override: ["Designer", "Illustrator"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AftonLin_Overview_006.jpeg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AftonLin_Overview_001.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AftonLin_Overview_002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_AftonLin_Overview_0013.gif"
    ]
  },
  {
    type: "",
    name: "Kody Kohlman",
    bio: "",
    stats: [],
    skills: ["Photographer", "Director"],
    reel: [
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KodyKohlman_Overview_004.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KodyKohlman_Overview_002.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KodyKohlman_Overview_006.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KodyKohlman_Overview_005.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KodyKohlman_Overview_003.jpg",
      "https://arthousedev.wpengine.com/wp-content/uploads/AH_KodyKohlman_Overview_001.jpg"
    ]
  },

];

export default talent;
