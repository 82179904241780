import React from "react";
import TagManager from "./TagManager";
import TalentView from "./TalentView";
import talent from "../shared/talent";
import SearchBar from "./SearchBar";
import debounce from "lodash.debounce"

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      talent: this.shuffleTalent(talent),
      filtered_talent: this.filteredTalent([], talent),
      active_tags: [],
      search_text: "",
      pagination: {
        page: 1,
        items_per_page: 10
      },
      search_slider_open: false,
      mobile_viewport: false,
      isLoading: false,
      hasMore: true,
    };

    this.mainRef = React.createRef();

    window.onscroll = debounce(() => {
      const {
        addNextTalents,
        state: {
          error,
          isLoading,
          hasMore,
        },
      } = this;

      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (error || isLoading || !hasMore) return;
      const parent = this.mainRef.current.parentElement
      const bounds = parent.getBoundingClientRect()
      // console.log(bounds)
      // console.log(window.innerHeight + document.documentElement.scrollTop, this.getElemDistance(parent) + bounds.height)
      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop
        >= this.getElemDistance(parent) + bounds.height
      ) {
        addNextTalents(window.scrollY);
      }
    }, 100);
  }

  getElemDistance = ( elem ) => {
    var location = 0;
    if (elem.offsetParent) {
        do {
            location += elem.offsetTop;
            elem = elem.offsetParent;
        } while (elem);
    }
    return location >= 0 ? location : 0;
};

  addNextTalents = (scrollValue) => {
    const state = this.state;
    state.isLoading = true;
    this.setState(state, () => {
      state.pagination.page += 1
      this.setState(state, () => {
        state.isLoading = false
        this.setState(state)
        window.scrollTo(0,scrollValue)
      })
    })
  }

  shuffleTalent = talent => {
    const top_talent = talent.filter((e, i) => i < 10);
    const other_talent = talent.filter((e, i) => i > 9);

    const top_indexes = new Array(top_talent.length).fill(0).map((el, i) => i);
    const indexes = new Array(other_talent.length).fill(0).map((el, i) => i);

    const shuffled_talent = [];

    top_talent.forEach((el, i, arr) => {
      const selection_index = Math.floor(
        Math.random() * (top_indexes.length - 1)
      );
      const t = top_talent[top_indexes[selection_index]];
      top_indexes.splice(selection_index, 1);
      shuffled_talent.push(t);
    });

    other_talent.forEach((el, i, arr) => {
      const selection_index = Math.floor(Math.random() * (indexes.length - 1));
      const t = other_talent[indexes[selection_index]];
      indexes.splice(selection_index, 1);
      shuffled_talent.push(t);
    });
    return shuffled_talent;
  };

  componentDidMount = () => {
    this.setMobileView();
    window.addEventListener("resize", this.setMobileView);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.setMobileView);
  };

  setMobileView = () => {
    const state = this.state;
    const x = window.innerWidth;
    if (state.mobile_viewport === undefined) {
      state.mobile_viewport = x < 600;
      this.setState(state);
      return;
    }
    if (state.mobile_viewport && x > 599) {
      state.mobile_viewport = false;
      this.setState(state);
    } else if (!state.mobile_viewport && x < 600) {
      state.mobile_viewport = true;
      this.setState(state);
    }
  };

  setTableElement = el => {
    const state = this.state;
    state.table_element = el;
    this.setState(state);
  };

  nextPage = () => {
    let state = this.state;
    state.pagination.page += 1;
    this.scrollToTop();
    this.setState(state);
  };

  prevPage = () => {
    let state = this.state;
    state.pagination.page -= 1;
    this.scrollToTop();
    this.setState(state);
  };

  jumpToPage = page => {
    let state = this.state;
    state.pagination.page = Number(page);
    this.scrollToTop();
    this.setState(state);
  };

  scrollToTop = () => {
    window.scrollTo(
      0,
      window.scrollY + this.state.table_element.getBoundingClientRect().top - 15
    );
  };

  displayedTalent = (talent, i) => {
    return i < this.state.pagination.page * this.state.pagination.items_per_page
  };

  currentRange = (page, items_per_page) => {
    return {
      low: items_per_page * (page - 1) + 1,
      high: items_per_page * page
    };
  };

  getPageCount = items_total => {
    return Math.ceil(items_total / this.state.pagination.items_per_page);
  };

  getActiveTags = () => {
    return this.state.active_tags;
  };

  filteredTalent = (criteria, talent) => {
    const set = talent.filter((t, i) => {
      return (
        criteria
          .map(funct => {
            return funct(t, i);
          })
          .filter(check => {
            return check;
          }).length === criteria.length
      );
    });

    return set;
  };

  tagsCheck = talent => {
    return (
      this.state.active_tags.filter(tag => {
        return (
          talent.skills.indexOf(
            `${tag.charAt(0).toUpperCase()}${tag.slice(1)}`
          ) === -1
        );
      }).length === 0
    );
  };

  searchCheck = talent => {
    const match = this.state.search_text;
    if (!match || match.length < 3) return true;

    const match_found = Object.keys(talent).some(key => {
      let value = talent[key];
      if (typeof value === "string") value = [value];
      return (
        value.filter(v => {
          return this.stringContainsCaseInsensitive(v, match);
        }).length > 0
      );
    });

    return match_found;
  };

  stringContainsCaseInsensitive = (str, match) => {
    if (!str || str.length === 0) return;
    return str.toLowerCase().indexOf(match.toLowerCase()) !== -1;
  };

  toggleTag = tag => {
    const state = this.state;
    const index = state.active_tags.indexOf(tag);
    if (index === -1) {
      state.active_tags.push(tag);
    } else {
      state.active_tags.splice(index, 1);
    }
    state.pagination.page = 1;
    this.setState(state);
  };

  setTag = tag => {
    const state = this.state;
    const index = state.active_tags.indexOf(tag);
    let set = [];
    if (tag === "") {
      set = [];
    } else if (index === -1) {
      set.push(tag);
    } else {
      set = [];
    }
    state.active_tags = set;
    state.pagination.page = 1;
    this.setState(state);
  };

  updateSearch = text => {
    const state = this.state;
    state.search_text = text;
    state.pagination.page = 1;
    this.setState(state);
  };

  isSearchSliderOpen = () => {
    return this.state.search_slider_open;
  };

  toggleSearchSlider = () => {
    const state = this.state;
    state.search_slider_open = !state.search_slider_open;
    this.setState(state);
  };

  render() {
    const filtered_talent = this.filteredTalent(
      [this.tagsCheck, this.searchCheck],
      this.state.talent
    );

    const paginated_talent = this.filteredTalent(
      [this.displayedTalent],
      filtered_talent
    );

    const table_view = this.state.mobile_viewport ? (
      <div className="mobile-talent-table-wrapper">
        <TalentView
          key={2}
          id={this.state.pagination.page / 2}
          talent={paginated_talent}
          rowSize={1}
          set={this.setTableElement}
        ></TalentView>
      </div>
    ) : (
      <div className="talent-table-wrapper">
        <TalentView
          key={1}
          id={this.state.pagination.page}
          talent={paginated_talent}
          rowSize={2}
          set={this.setTableElement}
        ></TalentView>
      </div>
    );

    const block_view = this.state.mobile_viewport ? (
      <React.Fragment>
        <div className="mobile-search-block">
          arthouse makes up an elite community of diverse artists.
          Working as a partner to agencies, brands and artists alike, we
          strategize, shoot, film, draw, design and deliver content from every
          discipline for every platform on a per-project basis.
          <br />
          <br />
          arthouse is any brand’s dream collaborator, because we put money where
          it matters—on the screen, straight from the source.
          <br />
          <br />
          <div className="search-text">
            <a href="mailto:info@arthouse.co" style={{ color: "#fc0d1b" }}>
              → Get in touch.
            </a>{" "}
            <br />
            Our team of production-savvy specialists will review your brief and
            recommend a curated pick of perfect-fit artists.
            <br />
            <br />
            <SearchBar onChange={this.updateSearch}></SearchBar>
            <br />
            <div className="arthouse-about-block">
              <a
                style={{ color: "black" }}
                href="arthousedev.wpengine.com/about"
              >
                why we exist
              </a>
              <br />
              <a
                style={{ color: "black" }}
                href="https://rw2productions.com/journal"
              >
                blog
              </a>
              <br />
              <a style={{ color: "black" }} href="https://rw2productions.com">
                powered by RW2
              </a>
            </div>
            <br />
            <div className="mobile-tag-manager">
              <div>filter by category:</div>
              <TagManager
                key={2}
                setTag={this.setTag}
                activeTags={this.getActiveTags}
                tags={[
                  { 
                    text: "directors", 
                    skill: "director", 
                    color: "#fc0d1b" 
                  },
                  {
                    text: "photographers",
                    skill: "photographer",
                    color: "#014ef0"
                  },
                  {
                    text: "illustrators+muralists",
                    skill: "illustrator/muralist",
                    color: "#59c276"
                  },
                  { 
                    text: "animators", 
                    skill: "animator", 
                    color: "#fed130" },
                  {
                    text: "designers",
                    skill: "VFX/designer",
                    color: "#a859c2"
                  },
                  {
                    text: "mixed media artists",
                    skill: "mixed media artist",
                    color: "#fd922f"
                  },
                  {
                    text: "experiential",
                    skill: "experiential",
                    color: "#63B4D1"
                  }
                ]}
                divisor={2}
              ></TagManager>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <div className={this.props.classNames}>
          <div className="skills-block">
            arthouse makes up an elite community of diverse artists.
            Working as a partner to agencies, brands and artists alike, we
            strategize, shoot, film, draw, design and deliver content from every
            discipline for every platform on a per-project basis.
            <br />
            <br />
            arthouse is any brand’s dream collaborator, because we put money
            where it matters—on the screen, straight from the source.
            <br />
            <br />
            filter by category:
            <TagManager
              key={1}
              setTag={this.setTag}
              activeTags={this.getActiveTags}
              tags={[
                { text: "directors", skill: "director", color: "#fc0d1b" },
                { text: "photographers", skill: "photographer", color: "#014ef0" },
                {
                  text: "illustrators+muralists",
                  skill: "illustrator/muralist",
                  color: "#59c276"
                },
                { text: "animators", skill: "animator", color: "#fed130" },
                {
                  text: "designers",
                  skill: "VFX/designer",
                  color: "#a859c2"
                },
                {
                  text: "mixed media artists",
                  skill: "mixed media artist",
                  color: "#fd922f"
                },
                {
                  text: "experiential",
                  skill: "experiential",
                  color: "#63B4D1"
                }
              ]}
              divisor={2}
            ></TagManager>
          </div>
          <div className="search-block">
            <div className="search-text">
              <a style={{ color: "red" }} href="mailto:info@arthouse.co">
                → Get in touch.
              </a>{" "}
              <br />
              Our team of production-savvy specialists will review your brief
              and recommend a curated pick of perfect-fit artists.
              <br />
              <br />
              <SearchBar onChange={this.updateSearch}></SearchBar>
              <div className="arthouse-about-block">
                <a
                  style={{ color: "black" }}
                  href="arthousedev.wpengine.com/about"
                >
                  why we exist
                </a>
                <br />
                <a
                  style={{ color: "black" }}
                  href="https://arthouse.co/journal"
                >
                  blog
                </a>
                {/* <a style={{ color: "black" }} href="https://rw2productions.com">
                  powered by RW2
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    return (
      <div  ref={this.mainRef} className={this.props.classNames}>
        {block_view}
        {table_view}
      </div>
    );
  }
}

export default Main;
