import React from "react";

class SkillSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      styles_default: { borderColor: this.props.color },
      divider: ""
    };

    this.state.styles = this.state.styles_default;
  }

  handleMouseHover = hovered => {
    const state = this.state;
    let set = state.styles_default;
    if (hovered) {
      set = { borderColor: this.props.color, color: this.props.color };
    }
    state.styles = set;
    this.setState(state);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.divider}
        <button
          ref="skill_button"
          className="skill-selector"
          onClick={() => {
            this.props.setTag(this.props.skill);
          }}
          onMouseEnter={() => {
            this.handleMouseHover(true);
          }}
          onMouseLeave={() => {
            this.handleMouseHover(false);
          }}
          style={this.state.styles}
        >
          {this.props.text}
        </button>
      </React.Fragment>
    );
  }
}

export default SkillSelector;
