import React from "react";

class PhotoReel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: props.photos[0],
      index: 0,
      delay: this.calculateDelay(this.props.id, 1000, 4000),
      interval: this.calculateDelay(this.props.id + 4, 1500, 3300)
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    setTimeout(this.updateImage, this.state.delay);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  calculateDelay = (index, min, max) => {
    return Math.sin(1 / (index + 1)) * (max - min) + min;
  };

  updateImage = () => {
    if (!this._isMounted) return;
    const state = this.state;
    state.index++;
    if (state.index === this.props.photos.length) {
      state.index = 0;
    }

    state.display = this.props.photos[state.index];
    state.timer = setTimeout(this.updateImage, this.state.interval);
    this.setState(state);
  };

  render = () => {
    return (
      <div className="photo-reel-wrapper">
        <img className="photo-reel-image" alt="portfolio" src={this.state.display || ""} />
      </div>
    );
  };
}

export default PhotoReel;
