import React from "react";
import "./App.css";
import Main from "./components/MainComponent";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };

    setTimeout(() => {
      const state = this.state;
      state.loading = false;
      this.setState(state);
      this.props.close();
    }, 2000);
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Main classNames={"main-app hide"}></Main>;
    }
    return <Main classNames={"main-app"}></Main>;
  }
}

export default App;
