import React from "react";
import ReactDOM from "react-dom";
import PhotoReel from "./PhotoReel";

class Card extends React.Component {

  color_names = ["salmon", "olive", "lilac", "beach", "seafoam", "warm-orange", "may-green"]

  constructor(props){
    super(props)
    this.state = {
      stored_talent: props.talent.name,
      classNames: "talent-name"
    }
  }

  componentDidMount = () => {

    ReactDOM.findDOMNode(this.refs.title).addEventListener("mouseenter", () => {
      
      const color = this.getRandomColor(this.color_names)

      const state = this.state;
      state.classNames += ` ${color}`
      this.setState(state)
    })

    ReactDOM.findDOMNode(this.refs.title).addEventListener("mouseleave", () => {
      const state = this.state
      state.classNames = "talent-name"
      this.setState(state)
    })

    if (typeof this.props.talent.reel !== "string") return;
    ReactDOM.findDOMNode(this.refs.video).load();
  };

  componentDidUpdate = () => {
    if (typeof this.props.talent.reel !== "string") return;
    if(this.state.stored_talent === this.props.talent.name) return
    const state = this.state
    state.stored_talent = this.props.talent.name
    this.setState(state)
    ReactDOM.findDOMNode(this.refs.video).load();
    
  };

  getRandomColor = (arr) => {
    const index = Math.floor(Math.random() * (arr.length - 1))
    return arr[index]
  }

  render() {
    const talent = this.props.talent;
    let reel;
    if (typeof talent.reel === "object") {
      reel = (
        <PhotoReel
          key={this.props.id}
          id={this.props.id}
          test={talent}
          photos={talent.reel || []}
        />
      );
    } else {
      //TODO - Make this its own component
      reel = (
        <div className="photo-reel-wrapper">
          <video
            ref="video"
            className="photo-reel-image"
            autoPlay
            playsInline
            loop
            muted
          >
            <source src={talent.reel}></source>
          </video>
        </div>
      );
    }
    return (
      <div
        className="talent-card"
        style={{
          width:
            this.props.width > 50
              ? `${this.props.width - 2}%`
              : `${this.props.width - 1}%`
        }}
      >
        <div className="talent-card-header">
          <a
            className="talent-link"
            href={`project/${talent.name
              .toLowerCase()
              .split(" ")
              .join("-")}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={this.state.classNames} ref="title">{this.props.talent.name}</div>
          </a>
          <div className="talent-badge"></div>
          <div className="talent-skill">
            {this.props.talent.skill_override
              ? this.props.talent.skill_override.join(" + ")
              : this.props.talent.skills.join(" + ")}
          </div>
        </div>
        <div className="talent-card-body">
          <a
            className="talent-link"
            href={`project/${talent.name
              .toLowerCase()
              .split(" ")
              .join("-")}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {reel}
          </a>
          <div className="talent-bio">{talent.bio}</div>
          <a
            className="talent-link"
            href={`project/${talent.name
              .toLowerCase()
              .split(" ")
              .join("-")}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              id="a61a126b-9e21-485c-afb3-2b52a82948a7"
              className="card-arrow"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20.35 12.23"
            >
              <title>RW2_Site-Arrow</title>
              <path
                d="M20.45,5.87,15,.58a.79.79,0,0,0-1.13,0,.8.8,0,0,0,0,1.13l4.07,4H.34v1.6H17.92l-4,3.91a.81.81,0,0,0,.56,1.38.79.79,0,0,0,.56-.23l5.42-5.29a.82.82,0,0,0,.24-.6A.8.8,0,0,0,20.45,5.87Z"
                transform="translate(-0.34 -0.35)"
                fill="#231f20"
              />
            </svg>
          </a>
        </div>
      </div>
    );
  }
}

export default Card;
