import React from "react";

class SearchBar extends React.Component {

  handleChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    return (
      <input
        className="talent-search"
        type="text"
        placeholder={
          this.props.placeholder
            ? this.props.placeholder
            : "Search for an artist or role"
        }
        onChange={this.handleChange}
      />
    );
  }
}

export default SearchBar;
